import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Apollo, gql } from 'apollo-angular';
import { Observable, Subject, combineLatest, filter, map, of, startWith, switchMap, take, takeUntil, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JsonSchemaService {
  constructor(
    private readonly formlyJsonschema: FormlyJsonschema,
    private readonly router: Router,
    private readonly httpService: HttpClient,
    private readonly apollo: Apollo
  ) {}

  loadJsonFile(): Observable<any> {
    return this.httpService.get<any>(`assets/json-schema/assigned_services.json`);
  }

  loadValues(url: string): Observable<any> {
    return this.httpService.get<any>(`assets/json-schema/${url}.json`);
  }

  generateFormWithSchema(schema: any, model: any, fields = []) {
    fields = [
      this.formlyJsonschema.toFieldConfig(schema, {
        map: field => {
          if (field.props['navigate']) {
            field.props['onClick'] = () => {
              this.router.navigate([field.props['navigate']]);
            };
          }

          if (field.key === 'nextButton') {
            if (field.props['gql']) {
              field.props['onClick'] = () => {
                // this.graphqlMutation(field.props['action']);
                // this.nextPage();
              };
            } else {
              // field.props['onClick'] = this.nextPage.bind(this);
            }
          } else {
            if (field.props['gql']) {
              // field.props['onClick'] = () => this.graphqlMutation(field.props['action']);
            }
          }
          return field;
        },
      }),
    ];
    return fields;
  }

  cssStringToObject(css: string): { [key: string]: string } {
    if (!css) return {};
    return css.split(';').reduce(
      (acc, style) => {
        const [property, value] = style.split(':').map(item => item.trim());
        if (property && value) {
          acc[property] = value;
        }
        return acc;
      },
      {} as { [key: string]: string }
    );
  }

  graphqlMutation(action: string, variables: object): void {
    const MUTATION = gql`
      ${action}
    `;
    this.apollo.mutate({ mutation: MUTATION, variables: variables }).pipe(take(1)).subscribe();
  }

  graphqlQuery(props: any): Observable<any> {
    const { gqlQuery } = props;
    const QUERY = gql`
      ${gqlQuery?.query}
    `;

    return this.apollo
      .query<any>({
        query: QUERY,
        variables: gqlQuery?.variables,
      })
      .pipe(
        filter((data: any) => data),
        map((result: any) => result?.data),
        map((data: any) => {
          const { fields } = gqlQuery;
          const getInnerValues = (obj: any): any[] => {
            if (Array.isArray(obj)) {
              return obj;
            } else if (typeof obj === 'object' && obj !== null) {
              return Object.values(obj).flatMap(value => getInnerValues(value));
            }
            return [];
          };

          const innerValues = Array.isArray(data) ? data : getInnerValues(data);
          const getNestedValue = (item: any, path: string) => {
            return path.split('.').reduce((acc, part) => acc && acc[part], item);
          };

          return innerValues.map((item: any) => ({
            label: getNestedValue(item, fields.label),
            value: getNestedValue(item, fields.value),
            ...fields.extras?.reduce(
              (acc, extra) => ({
                ...acc,
                [extra]: getNestedValue(item, extra),
              }),
              {}
            ),
          }));
        })
      );
  }

  singleItemGraphqlQuery(props: any): Observable<any> {
    const { gqlQuery } = props;
    const QUERY = gql`
      ${gqlQuery?.query}
    `;

    return this.apollo
      .query<any>({
        query: QUERY,
        variables: gqlQuery?.variables,
      })
      .pipe(
        filter((data: any) => data),
        map((result: any) => result?.data),
        map((data: any) => {
          const { fields } = gqlQuery;

          const extractedValue = Object.values(data)[0];

          if (!extractedValue) {
            return null;
          }

          return {
            label: extractedValue[fields.label],
            value: extractedValue[fields.value],
            ...fields.extras?.reduce(
              (acc, extra) => ({
                ...acc,
                [extra]: extractedValue[extra],
              }),
              {}
            ),
          };
        })
      );
  }

  /**
   * Extracts dynamic values from a variables object using a provided model.
   * This function searches for placeholders in the format {{ path.to.value }} within the
   * variables object, retrieves the corresponding values from the model,
   * and constructs a new object with these extracted values.
   *
   * @param variables - The object containing variables with placeholders.
   * @param model - The model object used to extract the actual values.
   * @returns An object with the extracted values for each variable.
   */
  variableExtractor(variables: object, model: object) {
    const pattern = /\{\{.*?\}\}/;
    const mutationVariables = new Object();

    for (const variable of Object.keys(variables)) {
      const dynamicValue = variables[variable];
      if (!pattern.test(dynamicValue)) continue;

      const start = dynamicValue.indexOf('{{') + 2;
      const end = dynamicValue.indexOf('}}');

      const valueStr = dynamicValue.slice(start, end).trim();
      const valueList = valueStr.split('.');

      let propertyValue = model;
      try {
        for (const value of valueList) {
          propertyValue = propertyValue[value];
        }
        mutationVariables[variable] = propertyValue;
      } catch (error) {
        console.error('Error extracting variables', error);
      }
    }

    return mutationVariables;
  }

  /**
   * Extracts nested variables from an object based on a given model.
   * @param variables - The object containing the variables.
   * @param model - The object representing the model.
   * @returns An object containing the extracted variables.
   */
  nestedVariableExtractor(variables: object, model: object) {
    const pattern = /\{\{.*?\}\}/;
    const mutationVariables = {};

    const extractValue = (valueList, obj) => {
      let propertyValue = obj;
      try {
        for (const value of valueList) {
          propertyValue = propertyValue[value];
        }
        return propertyValue;
      } catch (error) {
        console.error('Error extracting variables', error);
        return null;
      }
    };

    const traverseObject = (obj, result) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
              result[key] = [];
              for (const item of value) {
                const newItem = {};
                traverseObject(item, newItem);
                result[key].push(newItem);
              }
            } else {
              result[key] = {};
              traverseObject(value, result[key]);
            }
          } else if (typeof value === 'string' && pattern.test(value)) {
            const start = value.indexOf('{{') + 2;
            const end = value.indexOf('}}');
            const valueStr = value.slice(start, end).trim();
            const valueList = valueStr.split('.');
            const extractedValue = extractValue(valueList, model);
            if (extractedValue !== null) {
              result[key] = extractedValue;
            }
          }
        }
      }
    };

    traverseObject(variables, mutationVariables);

    return mutationVariables;
  }
  /**
   * Populates a template object with data values.
   *
   * @deprecated This function is deprecated. Use the `fillTemplate` function instead.
   * @param {object} template - The template object to populate.
   * @param {object} data - The data object containing values to populate the template.
   * @returns {object} - The populated template object.
   */
  populateTemplate(template, data) {
    function isObject(obj) {
      return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
    }

    function traverseAndPopulate(templateObj, dataObj) {
      for (const key in templateObj) {
        if (templateObj.hasOwnProperty(key)) {
          if (isObject(templateObj[key])) {
            if (dataObj && isObject(dataObj[key])) {
              // Recursively populate nested objects
              traverseAndPopulate(templateObj[key], dataObj[key]);
            } else if (dataObj) {
              // If templateObj[key] is an object but dataObj[key] is not, we need to find the matching keys in dataObj
              for (const subKey in templateObj[key]) {
                if (templateObj[key].hasOwnProperty(subKey) && dataObj.hasOwnProperty(subKey)) {
                  templateObj[key][subKey] = dataObj[subKey];
                }
              }
            }
          } else {
            // Directly assign the value from dataObj if it exists
            if (dataObj && dataObj.hasOwnProperty(key)) {
              templateObj[key] = dataObj[key];
            }
          }
        }
      }
    }

    traverseAndPopulate(template, data);
    return template;
  }

  /**
   * Generates a data model based on the provided object.
   * Recursively iterates through the object and creates a data model with keys and null values.
   * If a property has nested properties, it generates a nested data model.
   *
   * @param obj - The object to generate the data model from.
   * @param prefix - The prefix to be added to the keys of the generated data model.
   * @returns The generated data model.
   */
  generateDataModel(obj: any, prefix: string = ''): any {
    const keys: any = {};

    for (const [key, value] of Object.entries(obj)) {
      if (value['properties']) {
        keys[key] = this.generateDataModel(value['properties'], `${prefix}${key}.`);
      } else {
        keys[key] = null; // or you can keep an empty object to indicate presence of a key
      }
    }

    return keys;
  }

  /**
   * Finds an object in a nested object structure by its key.
   *
   * @param obj - The object to search in.
   * @param key - The key to search for.
   * @returns The first object that contains the specified key, or null if not found.
   */
  findObjectByKey(obj, key) {
    // Base case: if the object is null or undefined, return null
    if (!obj || typeof obj !== 'object') {
      return null;
    }

    // Check if the current object has the key
    if (key in obj) {
      return obj;
    }

    // Initialize a variable to store the result
    let result = null;

    // Iterate through each property in the object
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        // Recursively call findObjectByKey on nested properties
        result = this.findObjectByKey(obj[prop], key);

        // If result is found, break the loop
        if (result !== null) {
          break;
        }
      }
    }

    return result;
  }

  getValueByPath(obj, path) {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
  }

  setValueByPath(obj, path, value) {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const targetObj = keys.reduce((acc: any, key: string) => (acc[key] = acc[key] || {}), obj);
    targetObj[lastKey] = value;
  }

  /**
   * Fills the given template object with values from the data object based on the provided mapping.
   *
   * @param {object} template - The template object to be filled.
   * @param {object} dataObject - The data object containing the values to be filled into the template.
   * @param {object} mapping - The mapping object that defines the correspondence between template paths and data paths.
   * @returns {object} - The filled template object.
   */
  fillTemplate(template, dataObject, mapping) {
    for (const [templatePath, dataPath] of Object.entries(mapping)) {
      const value = this.getValueByPath(dataObject, dataPath);
      if (value !== undefined && this.getValueByPath(template, templatePath) === '') {
        this.setValueByPath(template, templatePath, value);
      }
    }
    return template;
  }
  //added to common service coz its used multiple times
  fetchGetScreenQuery(screenId: string, workflowId: string) {
    return gql`
    query {
      findNextPrevScreensByIdAndWorkflowId(
        id: "${screenId}"
        workflowId: "${workflowId}"
      ) {
        prevScreen {
          _id
          schema {
            title
            widget
            properties
            type
            required
          }
        }
        currentScreen {
          _id
          schema {
            title
            widget
            properties
            type
            required
          }
        }
        nextScreen {
          _id
          schema {
            title
            widget
            properties
            type
            required
          }
        }
      }
    }
  `;
  }

  listenToValueChanges(formControl, props: any) {
    return formControl.valueChanges.pipe(
      startWith(''),
      switchMap((value: string) => {
        if (
          props?.gqlQuery &&
          props?.gqlQuery?.query &&
          props?.gqlQuery?.fields &&
          props?.gqlQuery?.fields?.label &&
          props?.gqlQuery?.fields?.value
        ) {
          return this.filterGraphqlData(value, props);
        } else if (props?.url) {
          // return this.filterRestData(value);
        }
        return of([]);
      })
    );
  }

  initialDataLoad(props, field): void {
    if (
      props?.gqlQuery &&
      props?.gqlQuery?.query &&
      props?.gqlQuery?.fields &&
      props?.gqlQuery?.fields?.label &&
      props?.gqlQuery?.fields?.value
    ) {
      this.loadDataWithGql(props)
        .pipe(
          take(1),
          tap(res => {
            field.formControl.setValue(res.find(item => item.value === field.formControl.value));
          })
        )
        .subscribe();
    } else if (props?.url) {
      this.loadDataWithRest(props.url, props)
        .pipe(filter((data: any) => data))
        .subscribe();
    }
  }

  filterGraphqlData(filterValue, props): Observable<any> {
    return this.loadDataWithGql(props).pipe(
      filter((data: any) => {
        return data.filter((item: any) => {
          const label = item?.label?.toLowerCase();
          const searchValue = typeof filterValue === 'string' ? filterValue?.toLowerCase() : '';
          return label.includes(searchValue);
        });
      })
    );
  }

  filterRestData(filterValue, props) {
    return this.loadDataWithRest(props.url, props).pipe(
      filter((data: any) => data),
      filter((data: any) => {
        data.filter((item: any) => item?.label?.toLowerCase().includes(filterValue?.toLowerCase()));
        return data;
      })
    );
  }

  loadDataWithRest(url: string, props): Observable<any> {
    if (url) {
      return this.loadValues(url);
    } else {
      return of(props.options);
    }
  }

  loadDataWithGql(props: any): Observable<any> {
    return this.graphqlQuery(props);
  }

  findFormControlByName(name: string, fields: FormlyFieldConfig[]): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field && field.key === name) {
        return field;
      }
      if (field && field.fieldGroup) {
        const nestedField = this.findFormControlByName(name, field.fieldGroup);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }

  handleDependentFields(field: FormlyFieldConfig, fields: FormlyFieldConfig[], destroy$: Subject<void>): void {
    const dependOn = field.props['dependsOn'];
    const dependsOnValueMap = {};
    if (dependOn && dependOn.length) {
      dependOn.forEach((dep: { field: string; queryParam: string }) => {
        dependsOnValueMap[dep.queryParam] = this.findFormControlByName(dep.field, fields)?.formControl?.valueChanges;
      });
    }

    const observables = Object.values(dependsOnValueMap).filter(obs => obs instanceof Observable);
    const keys = Object.keys(dependsOnValueMap);
    if (observables.length > 0) {
      combineLatest(observables)
        .pipe(
          takeUntil(destroy$),
          tap(values => {
            const combinedValues = {};
            values.forEach((val: any, index) => {
              combinedValues[keys[index]] = val?.value ?? '';
            });
            Object.entries(combinedValues).forEach(([key, value]) => {
              field.props['gqlQuery'].variables[key] = value;
            });
            field.props.options = this.loadDataWithGql(field.props).pipe(
              filter(result => !!result),
              take(1)
            );
            // trigger value changes programmatically
            field.formControl.updateValueAndValidity();
          })
        )
        .subscribe(options => {
          field.templateOptions.options = options;
          field.props.options = options;
          field.formControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        });
    }
  }

  findFormlyFieldConfig(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | undefined {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }

      // If the field has nested fields (like in fieldGroup), recursively search there
      if (field.fieldGroup && field.fieldGroup.length) {
        const foundField = this.findFormlyFieldConfig(field.fieldGroup, key);
        if (foundField) {
          return foundField;
        }
      }
    }
    return undefined;
  }
}
